import React from 'react';
import Helmet from 'react-helmet';

import Copy, { Bold, ListItem, UnorderedList } from '@nib-components/copy';

import Layout from '../../components/Layout';
import { ContentfulDocument } from '../../constructs/models';
import metrics from '../../metrics';

import LifeLivingInsuranceSection, {
  LifeLivingInsuranceSectionProps,
} from '../../page-sections/life-living-insurance-section';
import { ContentHeroPanelProps } from '../../components/ContentHeroPanel';
import FactSheetImage from '../../img/life-and-living/fact-sheet.jpeg';
import { NavContentProps, NavLinkProps } from '../../components/NavContent';
import { ImportantInformationBannerProps } from '../../components/ImportantInformationBanner';
import FamilyDancingImage from '../../img/life-and-living/family-dancing.jpeg';
import Link from '@nib-components/link';
import { ExternalLinkSystemIcon } from '@nib/icons';
import { Box, Section, Stack } from '@nib/layout';
import Heading from '@nib-components/heading';
import { PrimaryButton } from '@nib-components/button';
import Accordion from '@nib-components/accordion';
import LifeLivingOptionsDropdown from '../../components/LifeLivingOptionsDropdown';
import { ContentBannerProps } from '../../components/ContentBanner';
import { urlConstants } from '../../constructs/constants';
import { colorTrueGreen } from '@nib-components/theme';
import WomanOnLaptopCalculatorImage from '../../img/life-and-living/woman-on-laptop-calculator.jpeg';

const title = 'About Life & Living Insurance | nib';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

const contentHeroData: ContentHeroPanelProps = {
  title: (
    <>
      Let’s talk about <span style={{ color: '#82E578' }}>Life & Living insurance.</span>
    </>
  ),
  subtextLeft: 'LIFE INSURANCE',
  subtextRight: 'LIVING INSURANCE',
  description: <>It’s healthy to talk about death. It’s even better to plan for it.</>,
  imageMobile: FamilyDancingImage,
  imageDesktop: FamilyDancingImage,
  imageAlt:
    'A joyful family dancing together in their cozy living room, filled with laughter and warmth.',
  type: 'banner',
};

const navigationLinks: NavLinkProps[] = [
  {
    text: 'About Life & Living insurance',
    isActive: true,
    href: urlConstants.aboutLifeLivingInsurance,
  },
  {
    text: 'Special offers',
    isActive: false,
    href: urlConstants.currentOfferLifeLivingInsurance,
  },
  {
    text: 'Why get Life & Living insurance',
    isActive: false,
    href: urlConstants.whyLifeLivingInsuranceCoverIsImportant,
  },
  {
    text: 'Estimator Tool',
    isActive: false,
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
  {
    text: 'For homeowners',
    isActive: false,
    href: urlConstants.lifeLivingHomeowners,
  },
  { text: 'How to apply', isActive: false, href: urlConstants.howToApplyLifeLivingInsurance },
];

const contentData = {
  title: 'Pick ‘n’ mix the coverage you need',
  description: (
    <>
      Our Life insurance and Living insurance options offer flexible choices for cover so that you
      can best protect what's important to you. ​
      <br />
      <br />
      Each of our Life insurance and Living insurance options provides financial support when you
      need it most, offering peace of mind for you and your loved ones.
    </>
  ),
};

const imageContentData = {
  title: 'What do I need?',
  description: (
    <>
      Whether you need Life Insurance to provide a lump sum for your family, Serious Illness Trauma
      Cover for major health conditions, or Income Protection Illness Cover to support you if you
      can’t work due to illness, this guide explains your options. Plus, every policy includes
      complimentary Funeral Expenses Cover to ease financial stress during difficult times.
      <br />
      <br />
      Download the fact sheet to explore flexible cover choices, eligibility, and how to get peace
      of mind with nib.
    </>
  ),
  buttonText: 'Get the Fact Sheet',
  buttonHref: urlConstants.lifeAndLivingInsuranceFactSheet,
  image: FactSheetImage,
  imageAlt:
    'A visual representation of life and living insurance, symbolising security and financial protection for individuals and families',
};

const faqData = {
  title: 'Frequently Asked Questions',
  description:
    'Here are some frequently asked questions about our Life insurance & Living insurance products that are available to purchase online or over the phone with our team of specialists.',
  content: [
    {
      title: 'Am I eligible?',
      content: (
        <>
          To apply for nib Life insurance & Living insurance online or through 0800 555 642, you'll
          need to complete an application form and be:
          <UnorderedList>
            <ListItem>Living in New Zealand and have one of the following:</ListItem>
            <UnorderedList listType="circle">
              <ListItem>New Zealand or Australian passport/citizenship</ListItem>
              <ListItem>
                New Zealand or Australian Permanent Resident Visa (with no travel conditions on your
                visa)
              </ListItem>
              <ListItem>
                New Zealand Resident Visa (for Life cover and/or Serious Illness Trauma cover only)
              </ListItem>
            </UnorderedList>
            <ListItem>18 - 70 years old to apply for Life Insurance (Life cover)</ListItem>
            <ListItem>
              18-60 years old to apply for Living Insurance (Serious Illness Trauma cover or Income
              Protection Illness cover)
            </ListItem>
            <ListItem>
              Employed for at least 25 hours per week for a single employer to apply for Living
              Insurance (Income Protection Illness cover)
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
    {
      title: 'How long does the application process take?',
      content: (
        <Copy>
          The time frame depends on your responses to the health and lifestyle questions In some
          cases, we may be able to offer immediate cover. If further information is needed, we will
          contact you and if necessary, your doctor, directly.{' '}
          <Link target="_blank" href={urlConstants.howToApplyLifeLivingInsurance}>
            Apply online today to get started
          </Link>
          .
        </Copy>
      ),
    },
    {
      title: 'How do I know how much /type of cover I need?',
      content: (
        <>
          You can use our online{' '}
          <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>Estimator Tool</Link>,
          which asks a few simple questions about your life stage to help you explore the type and
          level of cover that may suit you. Alternatively, you can speak with one of our{' '}
          <Link href={urlConstants.lifeLivingInsuranceContactUs}>
            friendly life insurance and living insurance specialists
          </Link>{' '}
          for guidance.
        </>
      ),
    },
    {
      title: 'Will my premium go up?',
      content: (
        <Stack space={3}>
          <Bold>Your premium will usually increase each year</Bold>
          <Copy measure={false}>
            Your premium is not fixed. Even if you maintain the same level of cover, your premium
            will typically increase as the insured person gets older. If you'd like an estimate of
            how your premiums may change over time, you can ask us for an indication. We may also
            adjust premiums as outlined in the section ‘Making changes to the insurance agreement’.
          </Copy>
          <Bold>Your cover will generally increase each year</Bold>
          <Copy measure={false}>
            To help maintain the value of your insurance, we automatically increase your cover
            amount each year on the anniversary of your Life & Living Insurance start date, while
            you’re eligible for this indexation. This increase is based on the Consumer Price Index
            (CPI), with a minimum of 1% and a maximum of 7%.
          </Copy>
          <Copy measure={false}>
            Please note that complimentary Funeral Expenses cover does not increase and remains a
            fixed payment of $15,000.
          </Copy>
        </Stack>
      ),
    },
    {
      title: 'Is there anything not covered?',
      content: (
        <Stack space={3}>
          <Copy measure={false}>
            Yes. General terms, conditions, and exclusions are{' '}
            <Link
              target="_blank"
              icon={ExternalLinkSystemIcon}
              href={urlConstants.lifeAndLivingInsuranceWording}
            >
              outlined in the policy wording
            </Link>
            .
          </Copy>
          <Copy measure={false}>
            Additionally, based on your health and lifestyle, specific personal exclusions may
            apply. If applicable, these will be disclosed for your review before you decide to
            proceed with the policy.
          </Copy>
        </Stack>
      ),
    },
  ],
};

const contentBannerContactData: ContentBannerProps = {
  title: 'How to apply',
  subtitle: 'Apply',
  description: (
    <Copy measure={false}>
      Talking about death or serious illness can be tough, but applying for Life & Living Insurance
      doesn’t have to be. Whether you’re ready to take the first step with our{' '}
      <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>Estimator Tool</Link> or
      would like further guidance, our specialists are here to help. You can{' '}
      <Link href={urlConstants.lifeLivingInsuranceContactUs}>contact us</Link> to speak with a
      specialist over the phone for personalised advice and support, making the process as simple
      and stress-free as possible.
    </Copy>
  ),
  image: WomanOnLaptopCalculatorImage,
  imageAlt: 'A smiling woman focused on her laptop, engaged in work with a positive demeanor.',
  primaryButtonText: 'First step: Estimate your cover',
  primaryButtonHref: urlConstants.estimateYourCoverageLifeLivingInsurance,
  secondaryButtonText: 'What do I need to apply?',
  secondaryButtonHref: urlConstants.howToApplyLifeLivingInsurance,
  flip: false,
  showAnimatedArrow: true,
  backgroundColor: 'brightGreen20',
};

const navigation: NavContentProps = {
  title: 'Life & Living insurance',
  homeHref: urlConstants.compareLifeAndLivingInsurance,
  primaryButton: { text: 'Apply now', href: urlConstants.estimateYourCoverageLifeLivingInsurance },
  secondaryButton: { text: 'Contact us', href: urlConstants.lifeLivingInsuranceContactUs },
  navigation: navigationLinks,
};

const importantInformationData: ImportantInformationBannerProps = {
  title: 'Important Information',
  description: [
    <Heading component="h3" color={colorTrueGreen}>
      *Get $300 Off Any Life & Living Insurance Policy - Terms and Conditions
    </Heading>,
    <Copy measure={false} size="small">
      You (the policy owner) are eligible for this offer (Offer) if you submit an application for a
      new Life & Living product (Eligible Policy) between 1 March and 30 June 2025 (or such later
      date as nib may permit in its absolute discretion) and your application for cover is accepted
      by nib.
      <br />
      <br />
      The Offer of $300 off will be applied as a credit on the Eligible Policy in the second month
      after your Eligible Policy is issued and after you’ve made the minimum premium payments:
      <br />
      <br />
      If you pay weekly, the first four weekly premium payments need to be paid
      <br />
      <br />
      If you pay fortnightly, the first two fortnightly premium payments need to be paid
      <br />
      <br />
      If you pay by another frequency, the first premium payment needs to be paid
      <br />
      <br />
      The credit is not transferable, refundable or redeemable for cash.
      <br />
      <br />
      Maximum credit value of $300 per Eligible Policy, limited to one Eligible Policy for any one
      person insured during this promotional period, between 1 March and 30 June 2025.
      <br />
      <br />
      The credit will automatically go towards future premiums on the Eligible Policy and when the
      credit runs out, premiums will be payable at the applicable rate and the Eligible Policy will
      continue to be charged on the selected payment frequency. The credit may result in a partial
      payment being due, before payments return to the regular premium amount.
      <br />
      <br />
      The Offer cannot be used in conjunction with any other Life & Living Insurance offers.
      <br />
      <br />
      These terms and conditions may be updated or the Offer cancelled by nib provided this does not
      prejudice anyone who has already received the Offer. They were last updated on 20 February
      2025. nib will publish any change on our website{' '}
      <Link href="/" icon={ExternalLinkSystemIcon} target="_blank">
        https://nib.co.nz
      </Link>
      .
      <br />
      <br />
      The Offer is promoted by nib nz insurance limited, 0800 555 642,{' '}
      <Link href="mailto:lifesales@nib.co.nz" icon={ExternalLinkSystemIcon}>
        lifesales@nib.co.nz
      </Link>
      . Level 9, 48 Shortland Street, Auckland City, 1010.
    </Copy>,
    <Heading component="h3" color={colorTrueGreen}>
      Temporary Accidental Death Cover
    </Heading>,
    <Copy measure={false} size="small">
      While you’re waiting for your application for Life & Living cover to be assessed, you’ll
      receive Temporary Accidental Death Cover.
    </Copy>,
    <Heading component="h3" color={colorTrueGreen}>
      Stand down periods
    </Heading>,
    <Copy measure={false} size="small">
      Stand down periods apply for these policies – this means you can’t claim for certain things
      immediately. Please read the full policy document for details.
    </Copy>,
    <Heading component="h3" color={colorTrueGreen}>
      You're not locked in
    </Heading>,
    <Copy measure={false} size="small">
      You get a 30-day free-look period when you join, to help you decide whether your cover is a
      good fit. We'll refund any premiums you've paid if you decide the cover isn't for you and you
      ask us to cancel in that first 30 days. If you ever feel your Life & Living Insurance cover is
      no longer what you need, we can talk you through your options.
    </Copy>,
    <Heading component="h3" color={colorTrueGreen}>
      Additional Information
    </Heading>,
    <Copy measure={false} size="small">
      Only the main features and benefits of the Life & Living Insurance and Temporary Accidental
      Death Cover are outlined here. For a full explanation of all benefits, exclusions and other
      terms and conditions of the cover, view the Policy documents:
      <UnorderedList>
        <ListItem size="small">
          <Link
            href={urlConstants.lifeAndLivingInsuranceWording}
            icon={ExternalLinkSystemIcon}
            target="_blank"
          >
            Life & Living Insurance
          </Link>
        </ListItem>
        <ListItem size="small">
          <Link
            href={urlConstants.temporaryAccidentalDeathCoverPDF}
            icon={ExternalLinkSystemIcon}
            target="_blank"
          >
            Temporary Accidental Death Cover
          </Link>
        </ListItem>
      </UnorderedList>
    </Copy>,
    <Copy measure={false} size="small">
      Life & Living Insurance is provided by nib nz insurance limited and is a related company of
      nib nz limited (“nib”). nib nz insurance limited issues and underwrites Life & Living
      Insurance products, and is solely responsible for claims under the cover. nib sells and may
      advise you on these products. Find out more about{' '}
      <Link href={urlConstants.financialStrength} icon={ExternalLinkSystemIcon} target="_blank">
        financial strength and financial advice with nib
      </Link>
      .
    </Copy>,
  ],
};

const lifeLivingSectionData: LifeLivingInsuranceSectionProps = {
  heroContent: contentHeroData,
  navigation: navigation,
  importantInformationData: importantInformationData,
  bannerProps: contentBannerContactData,
};

type DocumentResult = {
  edges: {
    node: {
      document: ContentfulDocument;
    };
  }[];
};

interface ImageShape {
  file: {
    url: string;
  };
}

interface HtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
  };
}
interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  leftButtonText: string;
  rightButtonText: string;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImage: HeroImagesShape;
  importantThingsToKnow: HtmlMarkdownShape;
}

type LifeCoverOptionsPageResult = {
  edges: {
    node: NodeShape;
  }[];
};

type GraphQLResult = {
  temporaryAccidentalDeathCover: DocumentResult;
  lifePolicyDocument: DocumentResult;
  factSheetUrl: DocumentResult;
  lifeCoverOptionsPage: LifeCoverOptionsPageResult;
};

interface LifeCoverOptionsProps {
  data: GraphQLResult;
}

const LifeAndLivingAbout = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options/about-life-living-insurance',
    url: 'https://www.nib.co.nz/life-cover-options/about-life-living-insurance',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['About Life & Living Insurance | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <LifeLivingInsuranceSection {...lifeLivingSectionData}>
        <MainContent />
      </LifeLivingInsuranceSection>
    </Layout>
  );
};

const MainContent = () => {
  return (
    <Section role="section" background="trueWhite">
      <Stack space={8}>
        <Box
          display="flex"
          justifyContent="start"
          flexDirection={{ xs: 'column', xxl: 'row-reverse' }}
          gap="4"
          padding="3"
        >
          <Stack space={4}>
            <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
              {contentData.title}
            </Heading>
            <Copy measure={false} color="deepBlack">
              {contentData.description}
            </Copy>
          </Stack>
        </Box>

        <LifeLivingOptionsDropdown />
        <ImageContent />
        <FAQContent />
      </Stack>
    </Section>
  );
};

const ImageContent = () => {
  return (
    <Box display="flex" flexDirection={{ xs: 'column', xxl: 'row-reverse' }} gap="4" padding="3">
      <Box>
        <img src={imageContentData.image} alt={imageContentData.imageAlt} />
      </Box>
      <Stack space={4}>
        <Box display="flex" justifyContent={{ xs: 'center', xl: 'start' }}>
          <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
            {imageContentData.title}
          </Heading>
        </Box>
        <Box display="flex" justifyContent="center">
          <Copy measure={false} color="deepBlack">
            {imageContentData.description}
          </Copy>
        </Box>
        <Box>
          <PrimaryButton
            target="_blank"
            icon={ExternalLinkSystemIcon}
            href={imageContentData.buttonHref}
          >
            {imageContentData.buttonText}
          </PrimaryButton>
        </Box>
      </Stack>
    </Box>
  );
};

const FAQContent = () => {
  return (
    <Stack space={4}>
      <Box display="flex" flexDirection="column" gap="4" padding="3">
        <Box display="flex" justifyContent={{ xs: 'center', xl: 'start' }}>
          <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
            {faqData.title}
          </Heading>
        </Box>
        <Copy measure={false}>{faqData.description}</Copy>
      </Box>
      <Box>
        <Accordion borderTop={false} borderBottom multiExpanded={false}>
          {faqData.content.map((faq, index) => (
            <Accordion.Item key={index} title={faq.title}>
              {faq.content}
            </Accordion.Item>
          ))}
        </Accordion>
      </Box>
    </Stack>
  );
};

export default metrics({ pageName: 'life-and-living-about-life-living-insurance' })(
  LifeAndLivingAbout
);
