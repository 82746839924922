import { PrimaryButton, SecondaryButton, TertiaryButton } from '@nib-components/button';
import Copy, { ListItem, UnorderedList } from '@nib-components/copy';
import Heading from '@nib-components/heading';
import {
  colorBrightGreen,
  colorBrightGreen20,
  colorSageGreen20,
  colorTrueGreen,
  colorTrueGreen20,
  colorWhite,
} from '@nib-components/theme';
import {
  CareGraphicIcon,
  ChevronDownSystemIcon,
  CloseSystemIcon,
  ExternalLinkSystemIcon,
  HealthInsuranceGraphicIcon,
  SavingsGraphicIcon,
  TickSystemIcon,
} from '@nib/icons';
import { Box, Column, Columns, Inline } from '@nib/layout';
import React, { useState } from 'react';
import styled from 'styled-components';
import { urlConstants } from '../../constructs/constants';
import Link from '@nib-components/link';

const BoxContainer = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 20px;
  gap: 16px;
  border-radius: 16px;
  background: ${(props: { isOpen?: boolean }) =>
    props.isOpen ? colorBrightGreen20 : colorSageGreen20};
  color: ${colorTrueGreen};
`;

const BoxContainerDropdown = styled.div`
  display: flex;
  padding: 24px;
  background: ${colorBrightGreen20};
  color: ${colorTrueGreen};
  flex-direction: column;
  border-radius: 20px;
  gap: 24px;
`;

const BoxTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colorTrueGreen20};
  width: 100%;
  padding-bottom: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  padding: 16px;
  background: ${colorBrightGreen};
  border-radius: 100px;
`;

const ButtonTrueGreen = styled(PrimaryButton)`
  background: ${colorTrueGreen};
  color: ${colorWhite};
`;

const FullHeightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 24px;
`;

enum OPTIONS {
  NONE = 0,
  FIRST = 1,
  SECOND = 2,
}

const LifeLivingOptionsDropdown = () => {
  const [optionSelected, setOptionSelected] = useState<OPTIONS>(OPTIONS.NONE);

  const handleToggle = (option: OPTIONS) => {
    if (optionSelected == option) {
      setOptionSelected(OPTIONS.NONE);
      return;
    }

    setOptionSelected(option);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', xl: 'row' }}
        justifyContent="space-between"
        gap={3}
      >
        <Columns collapseBelow="xl" space={{ xs: 4, xl: 8 }}>
          <Column flex={true} width={{ xs: '1', xl: '1/3' }}>
            <Box display="flex" flexDirection="column" gap={3} padding={3}>
              {/* Life Insurance */}
              <Box>
                <BoxContainer isOpen={optionSelected === OPTIONS.FIRST}>
                  <BoxTitleContainer>
                    <Heading size={2} component="h2" id={'-heading'}>
                      Life Insurance
                    </Heading>
                  </BoxTitleContainer>
                  <Box display="flex" flexDirection="column" gap={3}>
                    <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                      <IconContainer>
                        <CareGraphicIcon size="md" />
                      </IconContainer>
                      <Box>
                        <Heading size={3} component="h3">
                          Life insurance cover
                        </Heading>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Copy color="deepBlack" measure={false}>
                        Can provide a lump sum for your loved ones after you die or if you're
                        diagnosed with a terminal illness and are expected to die within the next 12
                        months.
                      </Copy>
                    </Box>
                    <Box display="flex" justifyContent="center" width="100%">
                      <TertiaryButton
                        large={true}
                        fullWidth={true}
                        onClick={() => handleToggle(OPTIONS.FIRST)}
                        icon={optionSelected === OPTIONS.FIRST ? ChevronDownSystemIcon : false}
                        aria-expanded={optionSelected === OPTIONS.FIRST}
                        aria-controls={'-dropdown'}
                        aria-labelledby={'-heading'}
                      >
                        {optionSelected !== OPTIONS.FIRST ? 'Learn more' : ''}
                      </TertiaryButton>
                    </Box>
                  </Box>
                </BoxContainer>
              </Box>
              <Box display={{ xs: 'flex', xl: 'none' }} flexDirection="column" gap={3}>
                {optionSelected === OPTIONS.FIRST && (
                  <>
                    <BoxContainerDropdown id={'life-insurance-dropdown'} tabIndex={-1}>
                      <Heading size={4} component="h3">
                        Life insurance cover
                      </Heading>
                      <Copy color="deepBlack" measure={false}>
                        Life insurance helps to protect your loved ones financially by providing a
                        lump sum payment if you pass away or are diagnosed with a terminal illness
                        and are expected to die in the next 12 months.
                        <br />
                        <br />
                        Only the main features and benefits of our Life & Living Insurance are
                        outlined here. For a full explanation of all benefits and exclusions, please
                        read the Life & Living Insurance policy document.
                        <br />
                        <br />
                        Use our{' '}
                        <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                          Estimator Tool
                        </Link>{' '}
                        to find out the level of cover you might want and what it might cost.
                      </Copy>
                      <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={5}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            How much do you need?
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              If you were to die, would there be enough money to cover your:
                              <br />
                              <br />
                            </Copy>
                            <UnorderedList space={0}>
                              <ListItem>family's immediate living costs?</ListItem>
                              <ListItem>
                                existing debts, like mortgages, credit cards and personal loans?
                              </ListItem>
                              <ListItem>funeral expenses?</ListItem>
                              <ListItem>future expenses like education for your children?</ListItem>
                            </UnorderedList>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            Maximum cover amount
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              You can apply for any amount of cover
                              <br />
                              <br />
                            </Copy>
                            <UnorderedList space={0}>
                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>
                                  Money is paid to you, your beneficiary, or your estate
                                </Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>One lump sum payment</Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>Must be 18 - 70 years old to apply</Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <CloseSystemIcon fill="error" />
                                <Copy measure={false}>
                                  No cover for passing away from self-inflicted harm during the
                                  first 13 months of cover
                                </Copy>
                              </Inline>
                            </UnorderedList>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            Funeral cover includes
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              With any Life & Living coverage options, you also get complimentary
                              Funeral Cover. This pays a lump sum of $15,000 to help with funeral
                              costs if you die or are diagnosed as terminally ill and are expected
                              to die within the next 12 months.
                              <br />
                              <br />
                            </Copy>
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={4}>
                        <SecondaryButton href={urlConstants.lifeAndLivingInsuranceWording}>
                          Read the policy document
                        </SecondaryButton>
                        <ButtonTrueGreen
                          href={urlConstants.estimateYourCoverageLifeLivingInsurance}
                        >
                          Estimate your coverage
                        </ButtonTrueGreen>
                      </Box>
                    </BoxContainerDropdown>
                  </>
                )}
              </Box>
            </Box>
          </Column>
          <Column flex={true}>
            {/* Living Insurance */}
            <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={3} padding={3}>
              <BoxContainer isOpen={optionSelected === OPTIONS.SECOND}>
                <BoxTitleContainer>
                  <Heading size={2} component="h2" id={'-heading'}>
                    Living Insurance
                  </Heading>
                </BoxTitleContainer>
                <FullHeightContainer>
                  <Columns collapseBelow="xl" space={5}>
                    <Column>
                      <Box display="flex" flexDirection="column" gap={3}>
                        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                          <IconContainer>
                            <HealthInsuranceGraphicIcon size="md" />
                          </IconContainer>
                          <Box>
                            <Heading size={3} component="h3">
                              Serious Illness Trauma Cover
                            </Heading>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <Copy color="deepBlack" measure={false}>
                            Can provide a lump sum if you've been diagnosed with a defined serious
                            medical condition.
                          </Copy>
                        </Box>
                      </Box>
                    </Column>
                    <Column>
                      <Box display="flex" flexDirection="column" gap={3}>
                        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                          <IconContainer>
                            <SavingsGraphicIcon size="md" />
                          </IconContainer>
                          <Box>
                            <Heading size={3} component="h3">
                              Income Protection Illness Cover
                            </Heading>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <Copy color="deepBlack" measure={false}>
                            Can provide a monthly payment if you can't work due to illness.
                          </Copy>
                        </Box>
                      </Box>
                    </Column>
                  </Columns>
                  <Box display="flex" justifyContent="center" width="100%">
                    <TertiaryButton
                      large={true}
                      fullWidth={true}
                      onClick={() => handleToggle(OPTIONS.SECOND)}
                      icon={optionSelected === OPTIONS.SECOND ? ChevronDownSystemIcon : false}
                      aria-expanded={optionSelected === OPTIONS.SECOND}
                      aria-controls={'-dropdown'}
                      aria-labelledby={'-heading'}
                    >
                      {optionSelected !== OPTIONS.SECOND ? 'Learn more' : ''}
                    </TertiaryButton>
                  </Box>
                </FullHeightContainer>
              </BoxContainer>
              {/* Life & Livng Mobile Dropdown */}
              <Box display={{ xs: 'flex', xl: 'none' }} flexDirection="column" gap={3}>
                {optionSelected === OPTIONS.SECOND && (
                  <>
                    <BoxContainerDropdown id={'life-insurance-dropdown'} tabIndex={-1}>
                      <Heading size={4} component="h3">
                        Serious Illness Trauma cover
                      </Heading>
                      <Copy color="deepBlack" measure={false}>
                        A serious illness can have a major impact on your life, but this cover can
                        help provide financial support to help you focus on recovery.
                        <br />
                        <br />
                        Only the main features and benefits of our Life & Living Insurance are
                        outlined here. For a full explanation of all benefits and exclusions, please
                        read the Life & Living Insurance insurance policy document.
                        <br />
                        <br />
                        Use our{' '}
                        <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                          Estimator Tool
                        </Link>{' '}
                        to find out the level of cover you might want and what it might cost.
                      </Copy>
                      <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={5}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            How much do you need?
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              If you were unable to work because of illness, would you have enough
                              money to cover:
                              <br />
                              <br />
                            </Copy>
                            <UnorderedList space={0}>
                              <ListItem>your current income?</ListItem>
                              <ListItem>
                                the difference between your current income and a disability benefit?
                              </ListItem>
                              <ListItem>your family's immediate living costs?</ListItem>
                              <ListItem>future expenses related to your illness?</ListItem>
                            </UnorderedList>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            Maximum cover amount
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              $1 million
                              <br />
                              <br />
                            </Copy>
                            <UnorderedList space={0}>
                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>Money is paid to you</Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>
                                  Full or partial payment of the amount insured depending on the
                                  severity of the specific illness
                                </Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>Must be 18 - 60 years old to apply</Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <CloseSystemIcon fill="error" />
                                <Copy measure={false}>
                                  No cover for any defined cancers in first 90 days
                                </Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <CloseSystemIcon fill="error" />
                                <Copy measure={false}>
                                  No cover if you die within 14 days of the diagnosis of a medical
                                  condition specifically defined in the policy
                                </Copy>
                              </Inline>
                            </UnorderedList>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            Funeral cover included
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              With any Life & Living coverage options, you also get complimentary
                              Funeral Cover. This pays a lump sum of $15,000 to help with funeral
                              costs if you die or are diagnosed as terminally ill and are expected
                              to die within the next 12 months.
                              <br />
                              <br />
                            </Copy>
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={4}>
                        <SecondaryButton
                          href={urlConstants.lifeAndLivingInsuranceWording}
                          icon={ExternalLinkSystemIcon}
                          target="_blank"
                        >
                          Read the policy document
                        </SecondaryButton>
                        <ButtonTrueGreen
                          href={urlConstants.estimateYourCoverageLifeLivingInsurance}
                        >
                          Estimate your coverage
                        </ButtonTrueGreen>
                      </Box>
                    </BoxContainerDropdown>
                  </>
                )}
                {optionSelected === OPTIONS.SECOND && (
                  <>
                    <BoxContainerDropdown id={'life-insurance-dropdown'} tabIndex={-1}>
                      <Heading size={4} component="h3">
                        Income Protection Illness cover
                      </Heading>
                      <Copy color="deepBlack" measure={false}>
                        If an illness prevents you from working, this cover can help replace lost
                        income so you can keep up with daily expenses and financial commitments.
                        <br />
                        <br />
                        Only the main features and benefits of our Life & Living Insurance are
                        outlined here. For a full explanation of all benefits and exclusions, please
                        read the Life & Living Insurance policy document.
                        <br />
                        <br />
                        Use our{' '}
                        <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                          Estimator Tool
                        </Link>{' '}
                        to find out the level of cover you might want and what it might cost.
                      </Copy>
                      <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={5}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            How much do you need?
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              If you were unable to work because of illness, would you have enough
                              money to cover: <br />
                              <br />
                            </Copy>
                            <UnorderedList space={0}>
                              <ListItem>your current income?</ListItem>
                              <ListItem>
                                the difference between your current income and a disability benefit?{' '}
                              </ListItem>
                              <ListItem>your family's immediate living costs?</ListItem>
                              <ListItem>future expenses related to your illness?</ListItem>
                            </UnorderedList>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            Maximum cover amount
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              55% of your gross income, up to a maximum of $6,000 per month <br />
                              <br />
                            </Copy>
                            <UnorderedList space={0}>
                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>Money is paid to you </Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>Two or five year cover period</Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>Must be 18 - 60 years old to apply</Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <TickSystemIcon fill="success" />
                                <Copy measure={false}>
                                  Must be employed for at least 25 hours per week for a single
                                  employer
                                </Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <CloseSystemIcon fill="error" />
                                <Copy measure={false}>No cover during the first 30 days</Copy>
                              </Inline>

                              <Inline nowrap={true} space={3} verticalAlign="center">
                                <CloseSystemIcon fill="error" />
                                <Copy measure={false}>
                                  Monthly payment may be reduced by your other income, including
                                  other payments such as a sickness or ACC benefit
                                </Copy>
                              </Inline>
                            </UnorderedList>
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Heading size={3} component="h4">
                            Funeral cover included
                          </Heading>
                          <Box color="deepBlack">
                            <Copy>
                              With any Life & Living coverage options, you also get complimentary
                              Funeral Cover. This pays a lump sum of $15,000 to help with funeral
                              costs if you die or are diagnosed as terminally ill and are expected
                              to die within the next 12 months.
                              <br />
                              <br />
                            </Copy>
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={4}>
                        <SecondaryButton
                          href={urlConstants.lifeAndLivingInsuranceWording}
                          icon={ExternalLinkSystemIcon}
                          target="_blank"
                        >
                          Read the policy document
                        </SecondaryButton>
                        <ButtonTrueGreen
                          href={urlConstants.estimateYourCoverageLifeLivingInsurance}
                        >
                          Estimate your coverage
                        </ButtonTrueGreen>
                      </Box>
                    </BoxContainerDropdown>
                  </>
                )}
              </Box>
            </Box>
          </Column>
        </Columns>
      </Box>
      <Box display={{ xs: 'none', xl: 'flex' }} flexDirection="column" gap={3} padding={3}>
        {optionSelected === OPTIONS.FIRST && (
          <>
            <BoxContainerDropdown id={'life-insurance-dropdown'} tabIndex={-1}>
              <Heading size={4} component="h3">
                Life insurance cover
              </Heading>
              <Copy color="deepBlack" measure={false}>
                Life insurance helps to protect your loved ones financially by providing a lump sum
                payment if you pass away or are diagnosed with a terminal illness and are expected
                to die in the next 12 months.
                <br />
                <br />
                Only the main features and benefits of our Life & Living Insurance are outlined
                here. For a full explanation of all benefits and exclusions, please read the Life
                & Living Insurance policy document.
                <br />
                <br />
                Use our{' '}
                <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                  Estimator Tool
                </Link>{' '}
                to find out the level of cover you might want and what it might cost.
              </Copy>
              <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={5}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    How much do you need?
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      If you were to die, would there be enough money to cover your:
                      <br />
                      <br />
                    </Copy>
                    <UnorderedList space={0}>
                      <ListItem>family's immediate living costs?</ListItem>
                      <ListItem>
                        existing debts, like mortgages, credit cards and personal loans?
                      </ListItem>
                      <ListItem>funeral expenses?</ListItem>
                      <ListItem>future expenses like education for your children?</ListItem>
                    </UnorderedList>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    Maximum cover amount
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      You can apply for any amount of cover
                      <br />
                      <br />
                    </Copy>
                    <UnorderedList space={0}>
                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>
                          Money is paid to you, your beneficiary, or your estate
                        </Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>One lump sum payment</Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>Must be 18 - 70 years old to apply</Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <CloseSystemIcon fill="error" />
                        <Copy measure={false}>
                          No cover for passing away from self-inflicted harm during the first 13
                          months of cover
                        </Copy>
                      </Inline>
                    </UnorderedList>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    Funeral cover includes
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      With any Life & Living coverage options, you also get complimentary Funeral
                      Cover. This pays a lump sum of $15,000 to help with funeral costs if you die
                      or are diagnosed as terminally ill and are expected to die within the next 12
                      months.
                      <br />
                      <br />
                    </Copy>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={4}>
                <SecondaryButton
                  href={urlConstants.lifeAndLivingInsuranceWording}
                  icon={ExternalLinkSystemIcon}
                  target="_blank"
                >
                  Read the policy document
                </SecondaryButton>
                <ButtonTrueGreen href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                  Estimate your coverage
                </ButtonTrueGreen>
              </Box>
            </BoxContainerDropdown>
          </>
        )}
        {optionSelected === OPTIONS.SECOND && (
          <>
            <BoxContainerDropdown id={'life-insurance-dropdown'} tabIndex={-1}>
              <Heading size={4} component="h3">
                Serious Illness Trauma cover
              </Heading>
              <Copy color="deepBlack" measure={false}>
                A serious illness can have a major impact on your life, but this cover can help
                provide financial support to help you focus on recovery.
                <br />
                <br />
                Only the main features and benefits of our Life & Living Insurance are outlined
                here. For a full explanation of all benefits and exclusions, please read the Life &
                Living Insurance policy document.
                <br />
                <br />
                Use our{' '}
                <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                  Estimator Tool
                </Link>{' '}
                to find out the level of cover you might want and what it might cost.
              </Copy>
              <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={5}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    How much do you need?
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      If you were unable to work because of illness, would you have enough money to
                      cover:
                      <br />
                      <br />
                    </Copy>
                    <UnorderedList space={0}>
                      <ListItem>your current income?</ListItem>
                      <ListItem>
                        the difference between your current income and a disability benefit?
                      </ListItem>
                      <ListItem>your family's immediate living costs?</ListItem>
                      <ListItem>future expenses related to your illness?</ListItem>
                    </UnorderedList>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    Maximum cover amount
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      $1 million
                      <br />
                      <br />
                    </Copy>
                    <UnorderedList space={0}>
                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>Money is paid to you</Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>
                          Full or partial payment of the amount insured depending on the severity of
                          the specific illness
                        </Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>Must be 18 - 60 years old to apply</Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <CloseSystemIcon fill="error" />
                        <Copy measure={false}>
                          No cover for any defined cancers in first 90 days
                        </Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <CloseSystemIcon fill="error" />
                        <Copy measure={false}>
                          No cover if you die within 14 days of the diagnosis of a medical condition
                          specifically defined in the policy
                        </Copy>
                      </Inline>
                    </UnorderedList>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    Funeral cover included
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      With any Life & Living coverage options, you also get complimentary Funeral
                      Cover. This pays a lump sum of $15,000 to help with funeral costs if you die
                      or are diagnosed as terminally ill and are expected to die within the next 12
                      months.
                      <br />
                      <br />
                    </Copy>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={4}>
                <SecondaryButton
                  href={urlConstants.lifeAndLivingInsuranceWording}
                  icon={ExternalLinkSystemIcon}
                  target="_blank"
                >
                  Read the policy document
                </SecondaryButton>
                <ButtonTrueGreen href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                  Estimate your coverage
                </ButtonTrueGreen>
              </Box>
            </BoxContainerDropdown>
          </>
        )}
        {optionSelected === OPTIONS.SECOND && (
          <>
            <BoxContainerDropdown id={'life-insurance-dropdown'} tabIndex={-1}>
              <Heading size={4} component="h3">
                Income Protection Illness cover
              </Heading>
              <Copy color="deepBlack" measure={false}>
                If an illness prevents you from working, this cover can help replace lost income so
                you can keep up with daily expenses and financial commitments.
                <br />
                <br />
                Only the main features and benefits of our Life & Living Insurance are outlined
                here. For a full explanation of all benefits and exclusions, please read the Life
                & Living Insurance policy document.
                <br />
                <br />
                Use our{' '}
                <Link href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                  Estimator Tool
                </Link>{' '}
                to find out the level of cover you might want and what it might cost.
              </Copy>
              <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={5}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    How much do you need?
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      If you were unable to work because of illness, would you have enough money to
                      cover: <br />
                      <br />
                    </Copy>
                    <UnorderedList space={0}>
                      <ListItem>your current income?</ListItem>
                      <ListItem>
                        the difference between your current income and a disability benefit?{' '}
                      </ListItem>
                      <ListItem>your family's immediate living costs?</ListItem>
                      <ListItem>future expenses related to your illness?</ListItem>
                    </UnorderedList>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    Maximum cover amount
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      55% of your gross income, up to a maximum of $6,000 per month <br />
                      <br />
                    </Copy>
                    <UnorderedList space={0}>
                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>Money is paid to you </Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>Two or five year cover period</Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>Must be 18 - 60 years old to apply</Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <TickSystemIcon fill="success" />
                        <Copy measure={false}>
                          Must be employed for at least 25 hours per week for a single employer
                        </Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <CloseSystemIcon fill="error" />
                        <Copy measure={false}>No cover during the first 30 days</Copy>
                      </Inline>

                      <Inline nowrap={true} space={3} verticalAlign="center">
                        <CloseSystemIcon fill="error" />
                        <Copy measure={false}>
                          Monthly payment may be reduced by your other income, including other
                          payments such as a sickness or ACC benefit
                        </Copy>
                      </Inline>
                    </UnorderedList>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Heading size={3} component="h4">
                    Funeral cover included
                  </Heading>
                  <Box color="deepBlack">
                    <Copy>
                      With any Life & Living coverage options, you also get complimentary Funeral
                      Cover. This pays a lump sum of $15,000 to help with funeral costs if you die
                      or are diagnosed as terminally ill and are expected to die within the next 12
                      months.
                      <br />
                      <br />
                    </Copy>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={4}>
                <SecondaryButton
                  href={urlConstants.lifeAndLivingInsuranceWording}
                  icon={ExternalLinkSystemIcon}
                  target="_blank"
                >
                  Read the policy document
                </SecondaryButton>
                <ButtonTrueGreen href={urlConstants.estimateYourCoverageLifeLivingInsurance}>
                  Estimate your coverage
                </ButtonTrueGreen>
              </Box>
            </BoxContainerDropdown>
          </>
        )}
      </Box>
    </>
  );
};

export default LifeLivingOptionsDropdown;
